import React, { useEffect, useState } from 'react';
import { Box, Typography, Chip, Divider, IconButton } from "@material-ui/core";
import { IconPlus } from '@paro.io/base-icons';
import { getFreelancerDetailsFn } from '../../utils/platformService';
import { getAllFreelancerRatingsAndReviews } from '../../services/apiService';
import FreelancerSection from './FreelancerSection';
import CachedIcon from '@material-ui/icons/Cached';

const ChatScreen = ({ fieldText, generatedSummary, setGeneratedSummary, activeStage, setActiveStage, handleNext }) => {

    const [loadingForm, setLoadingForm] = useState(true);
    const [freelancers, setFreelancers] = useState();
    const [loadingFls, setLoadingFls] = useState(false);
    const [showSummary, setShowSummary] = useState(false);

    useEffect(() => {
        if (activeStage === 0) {
            const data = sessionStorage.getItem("enh-proposal");
            if (data) {
                setGeneratedSummary(JSON.parse(data).generatedSummary);
            }
        }
    }, [activeStage, setGeneratedSummary]);

    useEffect(() => {
        const clearSessionStorage = () => {
            sessionStorage.clear();
        };

        window.addEventListener("beforeunload", clearSessionStorage);

        return () => {
            window.removeEventListener("beforeunload", clearSessionStorage);
        };
    }, []);

    useEffect(() => {
        const freelancersData = sessionStorage.getItem("enh-freelancers");
        if (freelancersData) {
            setFreelancers(JSON.parse(freelancersData));
        }
        setLoadingForm(false);
    }, []);

    useEffect(() => {
        const runMatch = async () => {
            await onRunMatch({
                notes: generatedSummary["Proposal Description"],
                software: generatedSummary["Required Software"],
                fasttrackServices:
                    generatedSummary["Required Services"]?.join(", "),
            });
        };

        if (!loadingForm && !freelancers) {
            runMatch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingForm]);

    const getClientRatings = async (flID) => {
        const res = await getAllFreelancerRatingsAndReviews(flID);
        const ratings = res.data.data.getAllFreelancerRatingsAndReviews;

        const overallRatingSum = ratings.reduce(
            (sum, rating) => sum + rating.overallRating,
            0
        );

        const overallRatingAvg =
            ratings.length > 0 ? overallRatingSum / ratings.length : 0;

        return { ratings, overallRatingAvg };
    };

    const onRunMatch = async (payload) => {
        setLoadingFls(true);
        try {
            const response = await fetch(
                "https://dq7fke59ni.execute-api.us-east-1.amazonaws.com/dev3/proposal_demo",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                }
            );

            const algoResponse = await response.json();

            algoResponse.recommendedFreeLancers = JSON.parse(
                algoResponse.recommendedFreeLancers
            )
                .sort((a, b) => b.score - a.score)
                .slice(0, 5)
                .map((fl) => ({ ...fl, flID: fl.freelancer_id }));

            const freelancers = await getFreelancerDetailsFn(algoResponse);

            for (let fl of freelancers) {
                const { ratings, overallRatingAvg } = await getClientRatings(fl.flID);
                fl.ratings = ratings;
                fl.overallRatingAvg = overallRatingAvg;
            }

            setFreelancers(freelancers);
            sessionStorage.setItem("enh-freelancers", JSON.stringify(freelancers));
        } catch (error) {
            console.error("Error:", error);
        }
        setLoadingFls(false);
    };

    const handleMatch = () => {
        const payload = {
            notes: generatedSummary["Proposal Description"],
            software:
                generatedSummary["Required Software"]?.map((software) => software.value).join(", ") || "",
            fasttrackServices: generatedSummary["Required Services"]?.join(", ") || "",
        };
        onRunMatch(payload);
        setShowSummary(true);
    };

    return (
        <Box>
            <Box style={{ borderBottom: '3px solid #2ca01c', paddingBottom: '6px', width: '100%' }} m={2}>
                <Typography variant="h4" align="left">Here's what you're telling us you need</Typography>
            </Box>
            <Box style={{ width: '30%', maxWidth: '80%', marginLeft: 'auto', backgroundColor: '#7CB5B5', borderRadius: '12px' }} p={1} m={2}>
                <Typography variant="body1" align="left">{fieldText}</Typography>
            </Box>
            <Box style={{ width: '80%' }} m={2} display='flex' flexDirection='row'>
                <div
                    style={{
                        height: 80,
                        width: 80,
                        border: '4px solid #F8FAFC',
                        borderRadius: '50%',
                    }}>
                    <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 36'%3e%3cpath fill='%232CA01C' d='M18 36c9.9411 0 18-8.0589 18-18 0-9.94114-8.0589-18-18-18C8.05886 0 0 8.05886 0 18c0 9.9411 8.05886 18 18 18Z'/%3e%3cpath fill='white' d='M12.0002 11c-3.86797 0-6.99996 3.136-6.99996 7 0 3.868 3.13199 7 6.99996 7h1.0001v-2.6h-1.0001c-2.42798 0-4.39995-1.972-4.39995-4.4 0-2.428 1.97197-4.4 4.39995-4.4h2.4041v13.6c0 1.436 1.1639 2.6 2.5999 2.6V11h-5.004ZM24.0038 25c3.868 0 6.9999-3.1361 6.9999-7 0-3.868-3.1319-7-6.9999-7h-1.0001v2.5999h1.0001c2.428 0 4.3999 1.9721 4.3999 4.4001s-1.9719 4.3999-4.3999 4.3999h-2.4041V8.79997c0-1.43602-1.1639-2.60002-2.5999-2.60002V25h5.004Z'/%3e%3c/svg%3e" alt="QuickBooks" width="36" height="36" data-testid="RwBrand_icon-logo" />
                </div>
                {generatedSummary && (
                    <Box style={{ marginRight: 'auto', backgroundColor: '#F9FFFF', borderRadius: '12px', border: '2px solid #CBD5E1' }} p={2} m={1}>
                        {!showSummary && <Box mt={2}>
                            <Typography variant='body1' style={{ color: "#0B6A6B" }} >*Based on your original input, we think you may also need these skills. If applicable, click to add.</Typography>
                            {generatedSummary["Required Services"]?.map((obj) => {
                                return <Chip
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            {obj}
                                            <span style={{ marginLeft: '8px', color: '#248384' }}>
                                                <IconPlus size="xs" />
                                            </span>
                                        </span>}
                                    key={obj}
                                    style={{ border: '2px solid #248384', color: '#060821', backgroundColor: '#EEFFFF', margin: '2px' }}
                                    onClick={() => setShowSummary(true)} />
                            })}
                            <IconButton onClick={handleMatch}><CachedIcon style={{ color: '#248384' }} /></IconButton>
                        </Box>}
                        {showSummary && <>
                            <Box className="mb-2" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                <Typography variant="body1">
                                    <strong>Proposal Title: </strong>
                                </Typography>
                                <Typography variant="body1" style={{ marginLeft: '6px' }}>{generatedSummary["Proposal Title"]}</Typography>
                                <br />
                                <Typography variant="body1">
                                    <strong>Summary Proposal: </strong>
                                </Typography>
                                <Typography variant="body1" style={{ marginLeft: '6px' }}>{generatedSummary["Proposal Description"]}</Typography>
                                <br />
                                <Typography variant="body1">
                                    <strong>Required Financial Services Needed:</strong>{" "}
                                </Typography>
                                {generatedSummary["Required Services"].map((obj) => {
                                    return <Typography variant="body1" style={{ marginLeft: '6px' }}>{obj}</Typography>
                                })}
                                <br />
                                <Typography variant="body1">
                                    <strong>Required Skills:</strong>{" "}
                                </Typography>
                                {generatedSummary["Required Skills"].map((obj) => {
                                    return <Typography variant="body1" style={{ marginLeft: '6px' }}>{obj}</Typography>
                                })}
                            </Box>
                            <Box m={2}><Divider orientation='horizontal' /></Box>
                            <FreelancerSection
                                freelancers={freelancers}
                                isMatchLoading={loadingFls}
                                setActiveStage={setActiveStage}
                            />
                        </>}
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default ChatScreen;